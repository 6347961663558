import styled from "styled-components";
import { spacing } from "@xolvio/xolvio-ui";

export const ContentWrapper = styled.div`
  padding: ${spacing.mobile.padding.default}px 0;

  width: auto;
  text-align: center;

  > * {
    text-align: center;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;
  }
  
  > p {
    font-family: "HK Grotesk",sans-serif;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #6b726f;
  }
`;
