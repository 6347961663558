import React from 'react'
import {SectionHeader, Subheading} from '@xolvio/xolvio-ui'
import {BlogWrapper} from './Homepage'
import {graphql, StaticQuery} from 'gatsby'
import Posts from './blog/Posts/Posts'

export const BlogInsightsSection = () => {

  return (
    <>
      <BlogWrapper id="blog">
        <Subheading>BLOG</Subheading>
        <SectionHeader>Our insights & news</SectionHeader>
      </BlogWrapper>

      <div style={{ maxWidth: '1140px', margin: '0 auto 60px', padding: '0 24px' }}>
        <Posts small />
      </div>
    </>
  )
}
