import React from "react";
import styled from "styled-components";
import colors from "../colors";
import { Link } from "@reach/router";
import { spacing } from "@xolvio/xolvio-ui";
import { PostMetadata } from "../Post/PostMetadata";

export const PostTile = post => {
  const {
    title,
    url,
    published_at,
    excerpt,
    reading_time,
    feature_image,
    authors
  } = post;
  const { name, profile_image } = authors[0];
  return (
    <StyledPost>
      <Link to={url}>
        <CoverImage src={feature_image} />
      </Link>
      <div>
        <Link to={url}>
          <h3>{title}</h3>
        </Link>
        <Excerpt>{excerpt}</Excerpt>
        <PostMetadata
          src={profile_image}
          name={name}
          dateString={published_at}
          readingTime={reading_time}
        />
      </div>
    </StyledPost>
  );
};

const CoverImage = styled.div`
  background-image: url(${props => props.src});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid ${colors.lightGrey};
  height: 170px;
  z-index: 1;
`;

const StyledPost = styled.li`
  position: relative;
  font-family: "HK Grotesk";
  margin: 0;
  list-style: none;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  width: 100%;
  color: ${props => (props.active ? colors.black : colors.textMedium)};

  h3 {
    font-size: 26px;
    margin-bottom: 0px;
    color: ${colors.textDark};
  }
  a {
    text-decoration: none;
    color: inherit;
    ${props => (props.active ? "font-weight: bold;" : "")}
  }
`;

const Excerpt = styled.p`
  margin: ${spacing.mobile.padding.default / 2}px auto
    ${spacing.mobile.padding.default}px;
  font-size: 16px;
  line-height: 1.7;
  font-family: "Merriweather Light";
`;
