import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import {
  CenteredContentWrapper,
  TextToutFullwidth,
  device,
  ThumbnailHeader,
  TextBody,
  ToutGroupNew,
  Services as ServicesComponent,
  ThreeUpCollage,
  spacing,
  ButtonInlineLink,
} from "@xolvio/xolvio-ui";

import {
  defaultHeroInlineProps,
  contactUsFooterProps,
  tout1,
  tout2,
  tout3,
  quote,
} from "../pages-content/homepage";
import { SectionHeader, Subheading, HeroNew } from "@xolvio/xolvio-ui";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { HeroTop } from "./HeroTop.js";
import { MoveHack, Spacing } from "./Services";
import { pageData } from "../pages-content/services";
import { BlogInsightsSection } from "./BlogInsightsSection";

const sectionSpacingMobile = "80px";
const sectionSpacingDesktop = "180px";

export const Homepage = () => (
  <StaticQuery
    query={graphql`
      query {
        hero: file(relativePath: { eq: "hero-bridge.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        bridge: file(relativePath: { eq: "bridge.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        productsHero: file(
          relativePath: { eq: "people_working_by_a_big_table.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        whiteTrian: file(relativePath: { eq: "white-trian.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tout: file(relativePath: { eq: "qualit-faster-book-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        testimonial1: file(
          relativePath: { eq: "people_talking_by_whiteboard.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        testimonial2: file(
          relativePath: { eq: "woman_and_man_looking_into_screen.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        yachts: file(relativePath: { eq: "sailing_yachts.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        collage1: file(
          relativePath: { eq: "office_people_working_on_laptops.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        collage2: file(
          relativePath: { eq: "people_talking_brainstorming.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        collage3: file(
          relativePath: { eq: "flow-diagram-software-development.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <HeroTop data={data} />

          <MoveHack top={"100px"} />

          <ThreeUpCollage
            subheading="WHAT WE DO"
            title="Unlock rapid, scalable delivery"
            highlightedTitleFragments={["scalable"]}
            contentBlock={
              <>
                At Xolvio, we eliminate the problematic and mundane so the
                interesting can flourish. We help you rapidly build complex,
                high-quality software that scales infinitely through a proven
                methodology we call
                <ButtonInlineLink
                  href={"/how-we-work/"}
                  style={{ padding: "0 0 0 5px" }}
                  as={"a"}
                >
                  Quality Faster
                </ButtonInlineLink>
                . This approach combines visual requirements facilitation,
                industry-leading software quality best practices, and
                future-proof architecture expertise for a 400% increase in
                delivery velocity when compared to typical agile teams.
              </>
            }
            imgLeft={
              <BackgroundImage
                fluid={data.collage1.childImageSharp.fluid}
                durationFadeIn={200}
              />
            }
            imgMain={
              <BackgroundImage
                fluid={data.collage3.childImageSharp.fluid}
                durationFadeIn={200}
              />
            }
            imgBottom={
              <BackgroundImage
                fluid={data.collage2.childImageSharp.fluid}
                durationFadeIn={200}
              />
            }
          />

          <MoveHack top={"180px"} topMobile={"80px"} />

          <ServicesWrapper>
            <ServicesComponent
              {...pageData.servicesDigitalExperienceIntegration}
              id="services"
            />
          </ServicesWrapper>

          <ServicesWrapper>
            <ServicesComponent
              {...pageData.servicesAcceleratedSoftwareTransformation}
              id="services"
            />
          </ServicesWrapper>

          <ProductsWrapper>
            <HeroNew
              inline
              id="products"
              {...defaultHeroInlineProps}
              elementWithBackgroundImage={
                <BackgroundImage
                  fluid={data.whiteTrian.childImageSharp.fluid}
                  durationFadeIn={200}
                  style={{ height: 693 }}
                />
              }
            >
              <ToutGroupNew
                style={{ margin: 0, marginBottom: 0 }}
                iconTout1={tout1}
                iconTout2={tout2}
              />

              <ToutGroupNew
                style={{ margin: 0, marginBottom: 0 }}
                iconTout1={tout3}
                quote={quote}
              />
            </HeroNew>
          </ProductsWrapper>

          <Spacing />

          <AlternateBackground>
            <CenteredContentWrapper id="howWeWork">
              <ContentWrapper>
                <Subheading style={{ textAlign: "center" }}>
                  HOW WE WORK
                </Subheading>
                <SectionHeader>The Quality Faster Methodology</SectionHeader>
                <TextBody style={{ margin: "auto", marginBottom: "30px" }}>
                  Refined and perfected over more than a decade now, the Quality
                  Faster methodology innovates on custom software development by
                  combining collaborative requirements facilitation, software
                  quality best practices, and malleable architecture expertise.
                </TextBody>

                <ToutRow>
                  <div>
                    <ThumbnailHeader style={{ margin: "auto" }}>
                      Visual requirements facilitation workshops
                    </ThumbnailHeader>
                    <TextBody style={{ margin: "auto", textAlign: "left" }}>
                      At Xolvio, we believe that requirements gathering is the
                      most difficult aspect of software delivery. This is why we
                      lead projects with accelerated, collaborative requirements
                      facilitation techniques that bring stakeholder knowledge
                      into the visual realm to inform software design.
                    </TextBody>
                  </div>
                  <div>
                    <ThumbnailHeader style={{ margin: "auto" }}>
                      Precision & accuracy via time-tested best practices
                    </ThumbnailHeader>
                    <TextBody style={{ margin: "auto", textAlign: "left" }}>
                      We have mastered and take cue from the industry-leading
                      best practices for high software quality: Domain-Driven
                      Design, Behavior-Driven Development, and Test-Driven
                      Development. This allows us to tame complexity and deliver
                      swiftly without compromising quality.
                    </TextBody>
                  </div>
                  <div>
                    <ThumbnailHeader style={{ margin: "auto" }}>
                      Future-proof, malleable architecture
                    </ThumbnailHeader>
                    <TextBody style={{ margin: "auto", textAlign: "left" }}>
                      Leveraging clean, hexagonal, and event-driven architecture
                      patterns with GraphQL APIs on top, we deliver systems that
                      can scale infinitely, boast superior performance, enable
                      unparalleled business intelligence, and that are built for
                      integration and CX flexibility—for years to come.
                    </TextBody>
                  </div>
                </ToutRow>
              </ContentWrapper>
            </CenteredContentWrapper>
          </AlternateBackground>

          <MoveHack topMobile={"40px"} />

          <div style={{ margin: "80px auto 50px" }}>
            <Subheading style={{ textAlign: "center", margin: "0 auto" }}>
              CUTTING-EDGE TECHNOLOGY
            </Subheading>
            <SectionHeader style={{ textAlign: "center", margin: "32px auto" }}>
              Our Partners
            </SectionHeader>
            <PartnerLogosWrapper>
              <a href="https://www.apollographql.com/" target="_blank">
                <img
                  src={"/assets/images/apollo-logo-mono.svg"}
                  width={"290px"}
                  alt={"apollo graphql logo"}
                />
              </a>
              <a href="https://escape.tech/" target="_blank">
                <img
                  src={"/assets/images/escape-logo-mono.svg"}
                  width={"280px"}
                  alt={"escape logo"}
                />
              </a>
              <a href="https://www.contentful.com/" target="_blank">
                <img
                  src={"/assets/images/contentful-logo-mono.svg"}
                  width={"280px"}
                  alt={"contentful logo"}
                />
              </a>
            </PartnerLogosWrapper>
          </div>

          <BlogInsightsSection />

          <TextToutFullwidth {...contactUsFooterProps} />
        </>
      );
    }}
  />
);

const PartnerLogosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  margin: 100px auto 40px;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const PostsWrapper = styled(CenteredContentWrapper)`
  > div {
    max-height: 420px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  @media ${device.tabletVertical} {
    > div {
      max-height: 400px;
      overflow: hidden;
    }
  }
`;

const ProductsWrapper = styled.div`
  > div {
    margin-bottom: -${sectionSpacingDesktop} !important;
  }
  h1 {
    max-width: 1000px !important;
  }
  svg {
    margin-top: 8px;
    @media ${device.mobile} {
      margin-top: 20px;
    }
    @media ${device.tablet} {
      margin-top: 20px;
    }
  }
`;

export const BlogWrapper = styled(CenteredContentWrapper)`
  padding-top: calc(${sectionSpacingMobile} + 25px);
  @media ${device.mobile} {
    padding-top: 25px;
  }

  @media ${device.tablet} {
    padding-top: calc(${sectionSpacingMobile});
  }
  @media ${device.desktop} {
    padding-top: calc(${sectionSpacingDesktop / 2});
  }
  * {
    text-align: center;
    max-width: 100%;
  }
`;

export const ToutRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 50px;

  @media ${device.mobile} {
    margin-bottom: 10px;
    > * {
      margin-bottom: 20px;
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
    margin-bottom: 80px;
    > * {
      margin-bottom: 0px;
    }
  }
`;

export const AlternateBackground = styled.div`
  background-color: rgb(237, 239, 238);
`;

const ServicesWrapper = styled.div`
  > div > div {
    padding-bottom: calc(${sectionSpacingMobile} + 120px);
    margin-bottom: calc(-${sectionSpacingMobile} - 40px);

    @media ${device.tabletVertical} {
      margin-top: calc(${sectionSpacingMobile});
      margin-bottom: calc(-${sectionSpacingMobile} - 90px);
    }

    @media ${device.desktop} {
      margin-bottom: calc(-${sectionSpacingDesktop});
    }
  }

  > div > div > div > div > div > svg {
    height: 100px;
    width: 100px;
  }
`;

const MobileSpacing = styled.div`
  @media ${device.mobile} {
    height: ${spacing.mobile.betweenSections}px;
  }
`;
