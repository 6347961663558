export const filterPosts = ({
  posts = [],
  skip = "",
  small = false,
  tagged = "devnote"
}) => {
  const filtered = posts.filter(p => {
    return p.node.tags
      ? p.node.tags.map(tag => tag.name).includes(tagged) &&
          !p.node.tags.map(tag => tag.name).includes("update") &&
          !p.node.tags.map(tag => tag.name).includes("hidden")
      : true;
  });

  return filtered
    .filter(p => (skip ? skip !== p.node.slug : true))
    .filter((p, i) => (small ? i < 3 : true));
};
