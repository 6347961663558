import {Clients, device, HeroNew} from '@xolvio/xolvio-ui'
import BackgroundImage from "gatsby-background-image";
import React from "react";
import styled from "styled-components";
import { defaultHeroProps } from "../pages-content/homepage.js";

export const clientImages = [
  ["assets/images/logos/2K_2021_Logo.svg", "80"],
  ["assets/images/logos/T-Mobile_logo.svg", "160"],
  ["assets/images/logos/Wayfair_logo.svg", "160"],
  ["assets/images/logos/Hewlett_Packard_Enterprise_logo.svg", "160"],
  ["assets/images/logos/Syneos_Health_logo.svg", "160"],
  ["assets/images/logos/Audi-Logo_2016.svg", "150"],
]

export function HeroTop({ data }) {
  return (
    <HeroWrapper>
      <HeroNew
        {...defaultHeroProps}
        children={<Clients logoBackgroundImages={clientImages.map(([imgSrc, imgWidth]) => (
          <div style={{ backgroundImage: `url(${imgSrc})`, width: `${imgWidth}px` }} />
        ))} />}
        elementWithBackgroundImage={
          <BackgroundImage fluid={data.hero.childImageSharp.fluid} />
        }
      />
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  margin-top: 6px;

  .hero-image-overlay {
    p {
      text-align: left;
      @media ${ device.mobile } {
        text-align: center;
      }
    }

    h1 {
      background-color: transparent;
      @media ${ device.mobile } {
        margin-top: 25px;
      }
    }

    @media ${ device.tabletVertical } {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media ${ device.desktop } {
      align-items: start;
      h1 {
        max-width: 600px;
      }
    }
    @media ${device.mobile} {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
